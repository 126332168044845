import React from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import Anchor from "@components/Anchor"
import PrimaryCta from "@components/PrimaryCta"
import { Heading2, dashedFocus, sectionSm } from "@styles/ui"

// Assets
import discussionGuide from "@images/dosing/PMG-download.svg"
import xywavLogo from "@images/logos/xywav-logo-small.svg"

const discussionGuidePdf = "https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"
const mywavUrl = "https://www.mywav.com/"
const listStyle = tw`list-disc list-outside text-xl leading-6 pl-5`
const subStyle = tw`list-disc list-outside pl-8 xl:pl-6`

const bulletList = [
  {
    text: "XYWAV is taken at night and divided into 2 doses",
  },
  {
    text: "Prepare your 2 doses at the same time, prior to bedtime, and put them in a safe and secure place. Before you take or give XYWAV, each dose should be mixed with approximately ¼ cup of water in the empty pharmacy containers included in the shipment",
  },
  {
    text: "Take your first dose while in bed and lie down immediately",
    subText: [
      {
        text: (
          <>
            XYWAV can cause sleep very quickly, without feeling drowsy. Some
            people fall asleep within 5&nbsp;minutes and most fall asleep within
            15&nbsp;minutes
          </>
        ),
      },
    ],
  },
  {
    text: "Take the second dose 2½ to 4 hours later, as instructed by your doctor, while in bed and lie down immediately after taking the medication. You should remain in bed after taking each dose of XYWAV",
  },
  {
    text: "If you miss the second dose, skip it",
    subText: [
      {
        text: "Never take 2 doses of XYWAV at one time",
      },
    ],
  },
  {
    text: "Wait at least 2 hours after eating before taking XYWAV",
  },
  {
    text: "For children, give the first XYWAV dose at bedtime or after an initial period of sleep, while your child is in bed and have them lie down immediately. Give the second XYWAV dose 2½ to 4 hours after the first XYWAV dose. You may want to set an alarm clock to make sure you wake up to give the second XYWAV dose. Your child should remain in bed after taking the first and second doses of XYWAV",
  },
]

const Dosing = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="orange">
      <Seo
        title="Dosing & Medication Guide for 2 Narcolepsy Symptoms | XYWAV®"
        description="Learn how to prepare and take XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero eyebrow="Dosing" gradient="orange">
        Learning how to prepare and take XYWAV
      </StaticHero>

      <section css={sectionSm}>
        <Container>
          <div tw="flex flex-col justify-center justify-self-center">
            <h2
              css={[
                Heading2,
                tw`mb-6 mx-3 text-center before:(block mb-6 h-1.5 rounded-md gradientRed mx-auto w-[100px] lg:w-[124px])`,
              ]}
            >
              Follow the instructions for proper preparation and dosing
            </h2>
          </div>
          <div tw="grid grid-cols-12 mt-[15px] md:(gap-x-10 items-center mt-[50px]) lg:(gap-x-0)">
            <div tw="order-2 col-span-full md:(order-1 col-span-6 mx-0 max-w-[470px])">
              <GatsbyImage
                tw="mx-auto max-w-[300px] flex justify-center mt-[15px] md:(mx-0 max-w-[470px])"
                image={getImage(data.laptopImage)}
                alt=""
                objectFit="contain"
              />
              <h2 css={[Heading2, tw`mt-8 md:mt-[75px]`]}>XYWAV Storage</h2>

              <ul
                css={[listStyle, tw`text-sm leading-5 md:(text-xl leading-6)`]}
              >
                <li tw="mt-4">
                  Prepared doses should be taken within 24&nbsp;hours
                  after&nbsp;mixing
                </li>
                <li tw="mt-2">
                  Store XYWAV in a safe place, away from children and&nbsp;pets
                </li>
              </ul>
            </div>
            <div tw="order-1 col-span-full md:(order-2 col-span-6 max-w-[578px])">
              <p tw="text-xl">
                When you receive your first shipment of XYWAV, you’ll receive
                the Patient Quick Start Guide along with your medication and
                detailed preparation and dosing instructions. Here’s an overview
                of what you’ll need to know to take XYWAV:
              </p>

              <ul css={listStyle}>
                {bulletList !== undefined &&
                  bulletList.map((item, i) => {
                    return (
                      <li key={i} tw="mt-[15px]">
                        {item.text}
                        {item.subText ? (
                          <ul css={subStyle}>
                            {item.subText.map((item, i) => {
                              return (
                                <li key={i} tw="mt-[15px]">
                                  {item.text}
                                </li>
                              )
                            })}
                          </ul>
                        ) : null}
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </Container>
      </section>

      {/* Doctor discussion guide */}
      <section css={[tw`bg-seashell py-[30px] md:py-[50px]`]}>
        <Container>
          <div tw="flex flex-col justify-between items-center xl:(gap-20 flex-row)">
            <div>
              <h2 css={Heading2}>XYWAV Medication Guide</h2>
              <p tw="text-sm my-[30px] font-medium xl:(text-xl leading-[1.4] mt-10 max-w-[570px])">
                Read the Instructions for Use in the{" "}
                <Anchor link={discussionGuidePdf} newTab>
                  Medication&nbsp;Guide
                </Anchor>{" "}
                for detailed instructions on preparing and taking XYWAV.
              </p>
            </div>
            <div tw="">
              <div tw="bg-white w-full max-w-[470px] rounded-[30px] p-6 pt-8 lg:(p-9)">
                <a
                  css={[tw`block w-full h-full`, dashedFocus]}
                  href={discussionGuidePdf}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={discussionGuide}
                    tw="w-[106px] mx-auto xl:(w-[114px])"
                    alt="Download the XYWAV Medication Guide"
                  />
                </a>
                <div tw="mt-8 pt-5 font-avenir font-medium text-sm border-t-2 border-mischka">
                  <p>
                    This{" "}
                    <Anchor link={discussionGuidePdf} newTab>
                      Medication&nbsp;Guide
                    </Anchor>{" "}
                    helps you with preparing and taking XYWAV.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section css={[sectionSm, tw`relative py-[30px] md:py-[50px]`]}>
        <Container>
          <img
            src={xywavLogo}
            tw="absolute opacity-20 w-[248px] left-[-100px] bottom-[-65px] lg:(w-[454px] left-[-250px] bottom-[-60px])"
          />
          <h3 css={[Heading2, tw`text-center`]}>
            Enroll in myWAV<sup tw="top-[-7px]">&trade;</sup> to access
            personalized support
          </h3>
          <p tw="text-center max-w-[323px] mx-auto my-[25px] xl:(max-w-[787px] my-[25px])">
            With the myWAV app, you can connect to educational resources and
            support, whenever and wherever you need it! You&apos;ll find answers
            to some of your questions, personalized encouragement, useful tips,
            and help with managing your treatment.
          </p>
          <div tw="text-center">
            <PrimaryCta url={mywavUrl} isExternal mdSmall>
              Enroll now
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Dosing

export const query = graphql`
  query {
    laptopImage: file(relativePath: { eq: "dosing/laptop.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: NONE)
      }
    }
  }
`
